<template>
  <div v-if="canAccess('auto_sender_emails')" class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <ProgressBar mode="indeterminate" v-if="processing"/>
        <DataTable :value="items" :filters="filters" class="p-datatable-responsive"
                   v-model:selection="currentItem" selectionMode="single" dataKey="id" :paginator="true"
                   paginatorPosition="bottom" :rows="10">
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Envío Automático de Correos</h5>
            </div>
          </template>

          <Column field="id" header="Código" :sortable="true"></Column>
          <Column field="send_hour" header="Hora Envio" :sortable="true"></Column>
          <Column header="Destinatarios">
            <template #body="slotProps">
              <ul>
                <li v-for="email of slotProps.data.to" :key="email">{{ email }}</li>
              </ul>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button v-if="canAccess('auto_sender_emails')" icon="pi pi-pencil"
                      class="p-button-rounded p-button-success p-mr-2"
                      @click="edit(slotProps.data)"/>
            </template>
          </Column>
        </DataTable>
        <Dialog v-if="canAccess('liquidation_mw_update')"
                v-model:visible="formDialog"
                :style="{width: '450px'}"
                header="Configuraciones Email"
                :modal="true"
                class="p-fluid">
          <br>
          <div class="p-field   p-fluid" v-if="currentItem">
            <span class="p-float-label">
               <InputText id="name" v-model.trim="currentItem.send_hour" required="true" autofocus name="send_hour"
                          :class="{'p-invalid': submitted && (!currentItem.send_hour)}" type="time"/>
                <label for="name">Hora Envío</label>
            </span>
            <small class="p-invalid" v-if="submitted && !currentItem.hour">La hora de envío es requerida</small>
          </div>
          <br>
          <div class="p-field   p-fluid" v-if="currentItem">
            <span class="p-float-label">
              <Chips  v-model="currentItem.to" :class="{'p-invalid': submitted && (!currentItem.to)}"/>
                <label for="name">Destinatarios</label>
            </span>
            <small class="p-invalid" v-if="submitted && !currentItem.hour">Los destinatarios son requeridos</small>
          </div>
          <br>
          <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveItem"/>
          </template>
        </Dialog>
      </div>
    </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>

  </div>
</template>

<script>
import service from '../../service/catalog.service'
import {mapState} from "vuex";
import bouncer from "../../../../helpers/bouncer";

export default {
  mixins: [bouncer],
  name: 'AutoSenderEmail',
  data() {
    return {
      processing: false,
      submitted: false,
      formDialog: false,
      filters: {},
      items: null,
      currentItem: null,
      layout: 'list',
      countries: [],
      selectedCountry: null,
      lastWeek: null,
    }
  },
  carService: null,
  eventService: null,
  nodeService: null,
  created() {

  },
  mounted() {
    if (this.canAccess('auto_sender_emails')) {
      this.getData()
    }
  },
  methods: {
    saveItem() {
      this.submitted = true;
      if (!this.currentItem.to || !this.currentItem.send_hour) {
        return;
      }
      this.processing = true;
      this.formDialog = false;
      service.save('auto-sender-emails',{
        to: this.currentItem.to,
        send_hour: this.currentItem.send_hour,
      }, this.currentItem.id).then(() => {
        this.getData();
        this.$toast.add({
          severity: 'success',
          summary: 'Registro guardado',
          detail: 'Se guardó correctamente',
          life: this.$utils.toastLifeTime()
        });

      }).catch((x) => {
        const message = x.response.data;
        this.$toast.add({severity: 'error', summary: 'Error', detail: this.$utils.formatError(message), life: this.$utils.toastLifeTime()});
      }).finally(() => {
        this.submitted = false;
        this.processing = false;
      });
    },
    hideDialog() {
      this.submitted = false;
      this.currentItem = {};
      this.formDialog = false;
    },
    getData() {
      this.processing = true;
      service.get('auto-sender-emails').then(x => {
        this.items = x.data;
      }).catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'se a producido un error',
          life: this.$utils.toastLifeTime()
        });
      }).finally(() => {
        this.processing = false;
      })
    },
    edit(item) {
      this.currentItem =JSON.parse(JSON.stringify(item));
      this.submitted = false;
      this.formDialog = true;

    }
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
  }
}
</script>

<style scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-progressbar {
  height: 1px;
}
</style>
